.quiz {
  h2 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }

  p {
    color: $forest-green;
  }

  .question {
    .lead {
      max-width: 680px;
      text-align: center;

      @include media-breakpoint-down(md) {
        p {
          line-height: 26px;
          font-size: 18px;
        }
      }
    }

    .btn-answer {
      background-color: $white;
      margin: 5px;
      position: relative;
      transition: none;
      border: 2px solid $forest-green;
      border-radius: 12px;
      color: $forest-green;
      font-size: 1.2rem;
      align-items: center;
      display: inline-flex;
      flex-direction: column;
      justify-content: space-evenly;
      width: 340px;
      height: 60px;
      line-height: 1.2;
      padding: 9px 40px;

      @include media-breakpoint-down(md) {
        font-size: 1.1rem;
        margin: 5px auto;
        width: 100%;
      }

      .animation {
        align-items: center;
        flex-direction: row-reverse;
        border-radius: 12px;
      }

      &:focus {
        .animation__active {
          display: flex;
          pointer-events: none;
          background-color: rgba(190, 209, 207, 0.5);
        }
      }

      &:disabled {
        opacity: 1;
      }

      &.wrong {
        pointer-events: none;
        background-color: rgb(178, 44, 55, 0.6);
        border: none;
        color: $white;

        .animation__wrong {
          display: flex;
          background-color: rgba(178, 44, 55, 0.5);
        }
      }

      &.correct {
        pointer-events: none;
        background-color: rgba(0, 89, 78, 0.8);
        border: none;
        color: $white;

        .animation__correct {
          display: flex;
          background-color: rgba(190, 209, 207, 0.5);
        }
      }
    }

    .disable {
      .btn {
        pointer-events: none;
        opacity: 1;
      }
    }

    .answer-section {
      .animation {
        display: none;
        position: absolute;
        transform: none;
        border-radius: 12px;
        color: $white;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;

        .icon {
          font-size: 3rem;
          color: white;
        }
      }
    }
  }
}

.results {
  min-height: 100vh;

  p {
    @include media-breakpoint-down(md) {
      font-size: 16px;
      line-height: 22px;
    }
  }
  .segment-image {
    text-align: center;
    img {
      max-width: 300px;

      @include media-breakpoint-down(md) {
        padding: 10px;
        width: 180px;
        margin-bottom: 10px;
      }
    }
  }

  .segment-descr {
    text-align: start;
    @include media-breakpoint-down(md) {
      text-align: justify;
      padding: 0 5px;
    }
  }

  .scorebar-descr {
    @include media-breakpoint-down(md) {
      font-size: 14px;
    }
  }

  .resultbar-wrap {
    padding: 0;
    @include media-breakpoint-down(md) {
      padding: 16px;
    }
  }

  @media only screen and (max-height: 670px) {
    .resultbar-wrap {
      padding: 5px;
    }
  }

  .result-bar {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;

    @include media-breakpoint-down(md) {
      padding: 0;
      margin-top: 10px;
    }

    .score-overlay {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      z-index: 2;
    }

    span {
      font-size: 16px;

      @include media-breakpoint-down(md) {
        font-size: 12px;
        line-height: 16px;
      }
    }

    .result-points {
      color: $forest-green;
      font-weight: 600;
      font-size: 28px;

      @include media-breakpoint-down(md) {
        font-size: 22px;
      }
    }
  }

  .share-wrapper {
    display: flex;
    align-items: center;
    border: none;
    justify-content: flex-start;
    height: 38px;
    background-color: transparent;
    padding: 3px 10px;

    @include media-breakpoint-down(md) {
      height: 30px;
      padding: 3px 0;
    }

    .btn {
      border: none;
    }

    svg {
      height: 25px;
      @include media-breakpoint-down(md) {
        height: 20px;
      }
    }
  }

  .share-button {
    margin: 1px 2px;
    display: inline-flex;
    @include media-breakpoint-down(md) {
      padding: 6px 8px;
    }
  }

  .share-button svg path {
    fill: $forest-green;
  }

  .share-button:hover svg path {
    fill: $gold; /* Fill color on hover */
  }

  .share-button:active svg path {
    fill: $gold; /* Fill color on click */
  }

  .result-buttons {
    display: flex;
    justify-content: center;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    .btn {
      width: 180px;
    }

    .btn-main {
      margin: 0 5px;
      @include media-breakpoint-down(md) {
        margin: 2px auto;
      }
    }

    .btn-again {
      border: 2px solid $forest-green;
      color: $forest-green;
      border-radius: 12px;
      padding: 14px auto;
      line-height: 22px;
      font-size: 16px;
      height: 56px;
      margin: 0 5px;

      &:hover,
      :active {
        border: 2px solid $gold;
        color: $gold;
      }

      @include media-breakpoint-down(md) {
        height: auto;
        margin: 5px auto;
      }
    }
  }
}

.human {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  .lead {
    z-index: 3;
    padding-top: 3rem;

    @include media-breakpoint-down(md) {
      padding-top: 7rem;
    }
  }

  @media only screen and (max-height: 670px) {
    .lead {
      padding-top: 2.6rem;
    }
  }

  @media only screen and (min-height: 1000px) {
    .lead {
      padding-top: 30%;
    }
  }

  .question-wrapper {
    position: absolute;
    width: 400px;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0) !important;

    @media (max-height: 900px) {
      width: 300px;
    }

    @include media-breakpoint-down(md) {
      width: 250px;
    }

    .answer-button-0 {
      position: absolute;
      top: 260px;
      right: -60px;

      @media (max-height: 900px) {
        top: 170px;
        right: -90px;
      }

      @include media-breakpoint-down(md) {
        top: 160px;
        right: -55px;
      }
    }

    .answer-button-1 {
      position: absolute;
      top: 410px;
      left: -60px;

      @media (max-height: 900px) {
        top: 290px;
        left: -90px;
      }

      @include media-breakpoint-down(md) {
        top: 240px;
        left: -55px;
      }
    }

    .answer-button-2 {
      position: absolute;
      top: 430px;
      right: -55px;

      @media (max-height: 900px) {
        top: 310px;
        right: -90px;
      }

      @include media-breakpoint-down(md) {
        top: 255px;
        right: -55px;
      }
    }

    .answer-button-3 {
      position: absolute;
      top: 570px;
      left: -60px;

      @media (max-height: 900px) {
        top: 420px;
        left: -95px;
      }

      @include media-breakpoint-down(md) {
        top: 350px;
        left: -55px;
      }
    }

    .answer-section {
      .btn-answer {
        width: 180px;
        z-index: 3;

        @include media-breakpoint-down(md) {
          width: 140px;
          height: 50px;
          line-height: 1.1;
          padding: 9px 10px;
        }

        &.wrong {
          pointer-events: none;
          background-color: rgb(178, 44, 55, 0.8);
          border: none;
          color: $white;

          .animation__wrong {
            display: flex;
            background-color: rgba(178, 44, 55, 0.5);
          }
        }

        &.correct {
          pointer-events: none;
          background-color: rgba(0, 89, 78, 0.8);
          border: none;
          color: $white;

          .animation__correct {
            display: flex;
            background-color: rgba(0, 89, 78, 0.5);
          }
        }
      }
    }

    g {
      .clicked {
        opacity: 0.6;
        path {
          fill: #b22c37;
        }
      }

      .clicked.correct {
        opacity: 0.6;
        path {
          fill: #00594e;
        }
      }
    }
  }
}

.single {
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 0;

  @include media-breakpoint-down(md) {
    padding: 2.5rem 0;
    align-content: start;
  }

  .question-wrapper {
    .question-image {
      margin: 2rem 0;
      img {
        max-height: 300px;

        @include media-breakpoint-down(md) {
          max-width: 300px;
        }
      }

      @include media-breakpoint-down(md) {
        margin: 1rem 0;
      }
    }
  }

  .answer-section {
    width: 700px;
    margin: 10px auto;

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
}

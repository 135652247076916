// icons
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css');

// --- Fonts ---

@font-face {
  font-family: 'KonkretGroteskPro';
  src: url('../fonts/KonkretGroteskPro/KonkretGroteskPro-Regular.woff2') format('woff2'),
    url('../fonts/KonkretGroteskPro/KonkretGroteskPro-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'KonkretGroteskPro';
  src: url('../fonts/KonkretGroteskPro/KonkretGroteskPro-Medium.woff2') format('woff2'),
    url('../fonts/KonkretGroteskPro/KonkretGroteskPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'KonkretGroteskPro';
  src: url('../fonts/KonkretGroteskPro/KonkretGroteskPro-Bold.woff2') format('woff2'),
    url('../fonts/KonkretGroteskPro/KonkretGroteskPro-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

// --- Body ---

html,
body {
  background-color: $white;
  margin: 0;
  padding: 0;
}

.main-container {
  color: $grey;
  min-height: 100vh;
  font-family: 'KonkretGroteskPro', sans-serif;
  background-image: url(/assets/images/donat-bg.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow-x: hidden;

  .eb-logo {
    position: absolute;
    bottom: 3px;
    right: 3px;
    height: 30px;
  }

  .wrapper {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    background: transparent;
  }

  .inner-wrapper {
    width: 900px;
    @include media-breakpoint-down(md) {
      width: 100%;
      padding: 0 10px;
    }
  }
}

p {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.btn {
  font-weight: 700;
}

.btn-main {
  border: none;
  border-radius: 12px;
  color: $white;
  background-color: $forest-green;
  font-size: 16px;
  line-height: 24px;
  padding: 16px 32px;
  margin: 10px auto;

  @include media-breakpoint-down(md) {
    line-height: 22px;
    padding: 14px 32px;
  }

  &:active {
    box-shadow: none;
    outline: none;
    background-color: $gold;
    color: $white;
  }

  &:hover {
    box-shadow: none;
    outline: none;
    background-color: $gold;
    color: $white;
  }
}

.multiple {
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @include media-breakpoint-down(sm) {
    align-content: start;
    padding: 3.5rem 0 4rem 0;
  }

  .multi-text {
    font-size: 16px !important;
    @include media-breakpoint-down(md) {
      font-size: 14px !important;
    }
  }

  .question-wrapper {
    .question-image {
      img {
        max-height: 400px;
        max-width: 310px;

        @include media-breakpoint-down(md) {
          max-height: 250px;
        }
      }

      @include media-breakpoint-down(sm) {
        margin: 1rem 0;
      }
    }
  }

  .answer-section {
    max-width: 400px;
    margin: 10px auto;

    @include media-breakpoint-down(md) {
      width: 100%;
    }

    .btn-answer {
      &.selected {
        background-color: $gold;
        color: white;
        border-color: $gold;
      }
    }
  }
}

.optin {
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  .optin-content {
    max-width: 600px;
    margin: 10px auto;

    @include media-breakpoint-down(md) {
      width: 100%;

      p {
        font-size: 16px;
        line-height: 20px;
      }

      label {
        font-size: 11px;
      }
    }
  }

  .h1-optin {
    font-size: 1.8rem;
    margin-top: 20px;

    @include media-breakpoint-down(md) {
      font-size: 1.4rem;
    }
  }

  .form-check {
    margin: 1rem auto;
  }

  .form-check-input {
    margin-top: 1px;

    @include media-breakpoint-down(md) {
      width: 1.2em;
      height: 1.2em;
    }
  }

  .optin-form {
    padding: 10px 50px;

    @include media-breakpoint-down(md) {
      padding: 10px;
    }
  }

  .optin-btn-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  .guest-result {
    border: none;
    color: $forest-green;

    &:active {
      box-shadow: none;
      outline: none;
      color: $gold;
    }

    &:hover {
      box-shadow: none;
      outline: none;
      color: $gold;
    }

    @include media-breakpoint-down(md) {
      font-size: 16px;
    }
  }

  .warning-text {
    text-align: center;
    color: $red;
  }
}

.glass {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-down(md) {
    padding: 3rem 0;
    align-content: center;
  }

  .lead {
    @include media-breakpoint-down(md) {
      margin-bottom: 2rem;
    }
  }

  .answer-section {
    text-align: center;
  }
}

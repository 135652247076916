.prizes {
  position: relative;

  .modal-content {
    max-width: 800px;
    margin: 10px auto;
    padding: 30px 10px;
    text-align: center;

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
  h1 {
    font-size: 45px;
    margin-bottom: 3rem;

    @include media-breakpoint-down(md) {
      font-size: 35px;
      margin-bottom: 2rem;
    }
  }

  h2 {
    font-size: 25px;
    color: $grey;
    font-weight: 400;
    margin-bottom: 2rem;

    @include media-breakpoint-down(md) {
      font-size: 22px;
    }
  }

  .prize-title {
    color: $forest-green;
  }

  .prize-description {
    text-align: justify;
  }

  p {
    font-size: 14px;
  }

  .btn-wrap {
    width: 180px;
    margin: 3rem auto;
    text-align: center;

    @include media-breakpoint-down(md) {
      margin: 1.2rem auto;
    }
  }

  .exit-button {
    background-color: $forest-green;
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    z-index: 2;

    &:active,
    &:focus {
      border: none;
      transform: scale(1.05);
    }

    @include media-breakpoint-down(md) {
      font-size: 1rem;
      padding: 0.5rem 0.8rem;
    }
  }

  .prize-wrapper {
    margin: 10px 0 30px 0;
    background-color: $green;
    flex-direction: column;
    border-radius: 12px;
    padding: 30px;
    display: flex;
    align-items: center;
    text-align: start;

    h3 {
      font-size: 22px;
    }

    p {
      font-size: 20px;
    }

    @include media-breakpoint-down(md) {
      text-align: justify;
      padding: 15px;

      h3 {
        text-align: center;
      }

      p {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }

  .prize-media {
    height: 250px;
    margin-right: 20px;

    @include media-breakpoint-down(md) {
      height: 200px;
      margin-right: 0;
      margin-bottom: 20px;
    }

    img {
      height: 100%;
      border-radius: 10px;
    }
  }

  .exit-button img {
    width: 25px;
    height: 25px;
    margin-bottom: 5px;

    @include media-breakpoint-down(md) {
      width: 15px;
      height: 15px;
      margin-bottom: 0;
    }
  }
}

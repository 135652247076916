// Colors

$white: #fff;
$black: #1c1e20;
$forest-green: #00594e;
$green: #bed1cf;
$grey: #464a4d;
$gold: #b2a286;
$grey-light: #71767a;
$grey-lines: #d0d3d6;
$red: #b22c37;

$headings-color: $forest-green;

$font-family-sans-serif: 'KonkretGroteskPro', sans-serif;
$headings-font-family: 'KonkretGroteskPro', sans-serif;

$utilities: (
  'custom-margin-top': (
    property: margin-top,
    class: mt,
    values: (
      0: 0,
      1: 0.4rem,
      2: 0.8rem,
      3: 1.2rem,
      4: 1.6rem,
      5: 2rem,
      6: 4rem,
    ),
  ),
  'opacity': (
    property: opacity,
    values: (
      0: 0,
      25: 0.25,
      50: 0.5,
      75: 0.75,
      90: 0.9,
      100: 1,
    ),
  ),
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4,
  7: $spacer * 9,
);

$theme-colors: (
  'black': $black,
  'white': $white,
  'forest-green': $forest-green,
  'green': $green,
  'grey': $grey,
  'gold': $gold,
);

$body-color: white;

// Typography
$font-size-base: 0.8rem;
$headings-font-weight: 700;
$headings-line-height: 1;
$h1-font-size: 2.25rem; //heading
$h2-font-size: 1.75rem; //question
$h3-font-size: 1.125; //descr

// Button
$btn-focus-box-shadow: 0;
$btn-font-family: $headings-font-family;
$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

// Form
$input-bg: $white;

$input-btn-font-size-sm: 1.2rem;
$input-border-color: $grey-lines;
$input-border-width: 1px;
$input-focus-border-color: $forest-green;
$input-color: $grey;
$input-focus-box-shadow: none;
$input-padding-x: 1.3rem;
$input-padding-y: 0.4rem;
$input-placeholder-color: $grey-lines;

$form-check-input-bg: $input-bg !default;
$form-check-input-width: 1.8em;
$form-check-radio-border-radius: 20%;
$form-check-input-border-radius: 20%;
$form-check-input-border: 1px solid $grey-lines;
$form-check-input-focus-border: 2px solid $forest-green;
$form-check-input-checked-color: $white;
$form-check-input-checked-bg-color: $forest-green;
$form-check-input-checked-border-color: $forest-green;
$form-check-input-focus-box-shadow: 'none';

// Progress
$progress-height: 1.2rem;
$progress-font-size: $font-size-base * 0.85;

// Cards
$card-border-radius: none;

$enable-negative-margins: true;

@import '~bootstrap/scss/bootstrap';

.intro {
  padding: 0 50px;

  @include media-breakpoint-down(lg) {
    padding: 0 5px;
  }

  @media screen and (max-width: 390px) and (min-height: 700px) {
    padding: 5px;
  }

  .intro-wrap {
    @include media-breakpoint-down(md) {
      padding: 15px auto 40px auto;
    }
  }

  .content-wrap {
    max-width: 600px;

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  .h1-welcome {
    font-size: 36px;
    line-height: 48px;

    @include media-breakpoint-down(lg) {
      font-size: 30px;
      line-height: 42px;
    }

    @include media-breakpoint-down(md) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  .intro-descr {
    @include media-breakpoint-down(md) {
      font-size: 16px;
      line-height: 20px;
      padding: 0 5px;
    }
  }

  .label {
    font-weight: 400;
  }

  .usps {
    margin-top: 1rem;
    padding: 0 10px;
    text-align: center;

    @include media-breakpoint-down(md) {
      padding: 0 5px;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: normal;

      @include media-breakpoint-down(md) {
        font-size: 15px;
      }
    }

    .icon {
      margin-bottom: 10px;

      @include media-breakpoint-down(md) {
        height: 35px;
      }
    }
  }

  .intro-img {
    height: 500px;
    text-align: end;
    margin-top: 25px;

    @include media-breakpoint-down(md) {
      height: 270px;
      text-align: center;
    }
  }

  .open-prize {
    font-size: 16px;
    color: $forest-green;

    span {
      cursor: pointer;
    }

    a {
      text-decoration: none;
      color: $forest-green;
    }
  }
}

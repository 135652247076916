.trivia {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  @include media-breakpoint-down(md) {
    width: 100%;
  }
  img {
    max-width: 320px;
    @include media-breakpoint-down(md) {
      width: auto;
      max-height: 290px;
      margin-bottom: 10px;
    }
  }

  p {
    @include media-breakpoint-down(md) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  .trivia-text {
    text-align: start;

    @include media-breakpoint-down(md) {
      text-align: justify;
    }
  }
}
